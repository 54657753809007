// extracted by mini-css-extract-plugin
export var SliderWidth = "Services-module--SliderWidth--4e2b9";
export var arrowImg = "Services-module--arrowImg--dfb76";
export var card = "Services-module--card--2d43f";
export var cardImg = "Services-module--cardImg--c25aa";
export var cardMian = "Services-module--cardMian--ee3a2";
export var cardWrapper = "Services-module--cardWrapper--a8bdb";
export var cards = "Services-module--cards--3ceef";
export var cmsWebIconCOntainer = "Services-module--cmsWebIconCOntainer--82ccf";
export var cmsWebIconCOntainerLeft = "Services-module--cmsWebIconCOntainerLeft--a0141";
export var description = "Services-module--description--979fe";
export var heading = "Services-module--heading--339ac";
export var headingPremium = "Services-module--headingPremium--2717f";
export var mainWorkFeaturesWebCms = "Services-module--mainWorkFeaturesWebCms--47697";
export var portfolioArrowIcon = "Services-module--portfolioArrowIcon--5d4a6";
export var portfolioArrowIconCover = "Services-module--portfolioArrowIconCover--70c46";
export var portfolioArrowRightIconCover = "Services-module--portfolioArrowRightIconCover--4a524";
export var premiumImg = "Services-module--premiumImg--bf4d6";
export var providingImg = "Services-module--providingImg--eb9d8";
export var teamButton = "Services-module--teamButton--a6c13";
export var trailBg = "Services-module--trailBg--6a58a";