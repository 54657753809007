import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./Choose.module.scss"

function Choose({ strapiData }) {
  return (
    <div className={styles.choose}>
      <Container>
        <Row className="align-items-center gap-30">
          <Col lg={6}>
            <p
              className={styles.subTitle}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle2,
              }}
            ></p>
            <h1
              className={`${styles.bannerHeading} `}
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            {/* <p
              className={`${styles.description} `}
              dangerouslySetInnerHTML={{
                __html: strapiData?.subTitle,
              }}
            /> */}
            <p   className={`${styles.description} `}>
              Whatsoever the idea might be, let's digitally transform it with
              the best possible infusions and a little touch of innovation. 
             <br/>
             <br/>
              Work side by side with the multidisciplinary teams. Now is the
              best time to make use of the latest tech trends to design,
              develop, and deploy your projects with the trendiest features. If
              you need a hand, we are here to give you digital transformation
              consulting services so you can stand bright in the technical
              horizons.
            </p>
          </Col>
          <Col lg={6}>
            <StaticImage
              src={
                "https://invozone-backend.s3.us-east-1.amazonaws.com/why_choose_us_f88cd0922a.webp"
              }
              alt="about us"
              placeholder="blurred"
              decoding="async"
              loading="lazy"
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Choose
