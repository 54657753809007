// extracted by mini-css-extract-plugin
export var accImg = "Transformation-module--accImg--abfdb";
export var approachImg = "Transformation-module--approachImg--dd4f5";
export var description = "Transformation-module--description--0ba88";
export var engineeringSQA = "Transformation-module--engineeringSQA--dffc8";
export var head = "Transformation-module--head--fbb9c";
export var heading = "Transformation-module--heading--feca5";
export var keyBtn = "Transformation-module--keyBtn--9b3f3";
export var nextGenRight = "Transformation-module--nextGenRight--d8a1e";
export var quesColor = "Transformation-module--quesColor--8689e";
export var quesColorSelected = "Transformation-module--quesColorSelected--b787d";
export var someColor = "Transformation-module--someColor--aec40";
export var textColor = "Transformation-module--textColor--f2b62";