import React from "react"
import * as styles from "./ServicesMobile.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const ServicesMobile = ({ strapiData }) => {
  return (
    <React.Fragment>
      <div className={styles.mobileWebCmsFeatures}>
        <Container>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: strapiData?.subTitle,
            }}
          />
          <p
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <Row className={styles.cardWrapper}>
            {strapiData?.cards &&
              strapiData?.cards?.map((e, i) => (
                <Col lg={6} md={6} xs={12} className="d-flex" key={i}>
                  <div className={styles.card}>
                    <lottie-player
                      autoplay
                      loop
                      src={e?.image1[0]?.localFile?.publicURL}
                      style={{
                        height: "60px",
                        width: "60px",
                        margin: "0px",
                      }}
                    ></lottie-player>
                    <h3>{e?.title}</h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: e?.subTitle,
                      }}
                    />
                  </div>
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ServicesMobile
