import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => {
  const [show, setShow] = React.useState(false)

  return (
    <div className={styles.portfolioAppWorkBanner}>
      <Container className={styles.bannerCon}>
        <div className={styles.fintech}>
          <video
            className={styles.backgroundVideo}
            autoPlay
            muted
            loop
            // poster="https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2024_07_22_233957_b57448a23f.webp"
          >
            <source
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/earth_4_1828_by_887_Color_Grade_79ac195fce.webm"
              type="video/mp4"
            />
          </video>
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col lg={10}>
                <div className={styles.Heading}>
                  <p
                    className={styles.subTitle}
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.title,
                    }}
                  ></p>
                  <h1
                    className={`${styles.bannerHeading} `}
                    dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }}
                  />
                  <p
                    className={`${styles.description} `}
                    dangerouslySetInnerHTML={{
                      __html: strapiData?.subTitle2,
                    }}
                  />
                </div>
                <Link to={"/contact-us/"} className={styles.btn}>
                  Book a 15 min call
                  <img
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                    decoding="async"
                    loading="lazy"
                    alt="explore icon"
                  />
                </Link>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Container>
        </div>
      </Container>
    </div>
  )
}

export default Banner
