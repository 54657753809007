// extracted by mini-css-extract-plugin
export var Heading = "Banner-module--Heading--86ceb";
export var backgroundVideo = "Banner-module--backgroundVideo--dee42";
export var bannerCon = "Banner-module--bannerCon--f0416";
export var bannerHeading = "Banner-module--bannerHeading--008be";
export var btn = "Banner-module--btn--199af";
export var description = "Banner-module--description--3c1f9";
export var fintech = "Banner-module--fintech--af430";
export var freshprepHeading = "Banner-module--freshprepHeading--f96b1";
export var portfolioAppWorkBanner = "Banner-module--portfolioAppWorkBanner--f50a8";
export var ser = "Banner-module--ser--c5258";
export var subTitle = "Banner-module--subTitle--d56bb";